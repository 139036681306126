<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12 sm12>
          <v-card outlined class="primary--border">
            <v-card-title class="title">
              Advance Practical Head
              <v-spacer></v-spacer>
              <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <add-button
                  permission="exam-create"
                  @action="
                    (form.dialog = true), $refs.form ? $refs.form.reset() : ''
                  "
                >
                  &nbsp; New Practical Head
                </add-button>
              </transition>
            </v-card-title>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="form.items.data"
              :options.sync="pagination"
              :server-items-length="form.items.meta.total"
              :loading="form.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ index + form.items.meta.from }}</td>
                  <td class="text-xs-left">
                    <strong>
                      {{ item.title }}
                     
                    </strong>
                  
                  </td>
                  <td class="text-xs-left">
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 1"
                    color="success"
                  >
                    Active</v-chip
                  >
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 0"
                    color="error"
                  >
                    Inactive</v-chip
                  >
                </td>
                  <td class="text-right">
                    
                    <edit-button
                      permission="exam-update"
                      @agree="form.edit(item)"
                    />
                    <delete-button
                      permission="exam-delete"
                      @agree="form.delete(item.id)"
                    />
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-dialog v-model="form.dialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Add/Update</span>
            </v-card-title>
            <v-card-text class="pb-2">
              <br />
              <v-form
                ref="form"
                @submit.prevent="save"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="save"
                v-model="valid"
                :lazy-validation="lazy"
              >
                <br />

                <v-text-field
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="form.title"
                name="name"
                :error-messages="form.errors.get('name')"
                :rules="[(v) => !!v || 'Name is required']"
              />

               <v-select
                :items="statuses"
                required
                class="pa-0"
                label="Status*"
                outlined
                dense
                v-model="form.status"
                :rules="[(v) => !!v || 'Status is required']"
                :error-messages="form.errors.get('status')"
              />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="(form.dialog = false), form.reset()"
                >Close</v-btn
              >
              <v-btn color="success" text @click="validate">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </template>
  <script>
  import { mapState } from "vuex";
  import Form from "@/library/Form";
  import Mixins from "@/library/Mixins";
  
  export default {
    mixins: [Mixins],

    data: () => ({
      valid: true,
      lazy: false,
      form: new Form(
        {
            id:"",
          title: "",
          status: "1",
        },
        "/api/practical-head"
      ),
      search: null,
     
      is_final: false,
      headers: [
        { text: "#", align: "center", value: "id", width: 50, sortable: false },
        { text: "Name", align: "left", value: "name" },
        { text: "Status", align: "left", value: "rank" },
        { text: "Action", align: "right", sortable: false, width: 400 },
      ],
      statuses: [
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "0",
      },
    ],
    }),
  
    computed: {
      ...mapState(["batch"]),
    },
  
    watch: {
      pagination: function() {
        this.get();
      },
      batch: function(value) {
        this.get();
      },
      "form.exam_original_name": function(value) {
        if (!this.form.name || !this.form.id) {
          this.form.name = value;
        }
      },
    },
  
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.save();
        }
      },
      get(params) {
      let extraParams = "status=" + "all";
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
  
      save() {
        if (this.is_final) this.form.is_final = 1;
        else this.form.is_final = 0;  
        this.form.store().then(() => {
          this.form.is_final = 0;
        });
      },
  
      editExam(id) {
        if (item.is_final === 1) this.is_final = true;
      },
      viewExam(id) {
        this.$router.push({
          name: "exam-detail",
          params: {
            examId: id,
          },
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>
  